import Vue from 'vue'
import createRouter from "./router";
import App from './App.vue';
const router = createRouter();


Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')