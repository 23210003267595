
module.exports = [
    {
        path: "/company/:company_id/products",
        component: () =>
            import("../views/products-screen.vue")
    },
    {
        path: `/company/:company_id/pricing`,
        component: () =>
            import(
                "../views/pricing.vue"
            ),
        meta: {
            name: "Pricing page"
        },
    },
    {
        path: `/company/:company_id/subscription-status`,
        component: () =>
            import(
                "../views/subscription-status.vue"
            ),
        meta: {
            name: "Subscription Status"
        },
    },
    {
        path: "/company/:company_id",
        name: "main",
        component: () =>
            import("../views/main.vue")
    }
]
